import React from 'react';

export default function ConCard() {
  return (
    <svg
      className="h-auto w-full"
      viewBox="0 0 934 1393"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_103_2281)">
        <path
          d="M73.182 1003.4L826.179 781.362C838.835 777.63 847.523 766.01 847.523 752.814V60.7627C847.523 44.3252 834.198 31 817.761 31H64.7641C48.3267 31 35.0014 44.3252 35.0014 60.7627V974.852C35.0014 994.73 54.1152 1009.02 73.182 1003.4Z"
          fill="#7F7F7F"
          fillOpacity="0.8"
        />
      </g>
      <g filter="url(#filter1_d_103_2281)">
        <path
          d="M775 1140.08V281.763C775 265.325 761.675 252 745.237 252H143.763C127.325 252 114 265.325 114 281.763V1323.79C114 1343.79 133.33 1358.1 152.457 1352.25L753.931 1168.54C766.449 1164.72 775 1153.16 775 1140.08Z"
          fill="white"
          fillOpacity="0.7"
        />
      </g>
      <g filter="url(#filter2_dd_103_2281)">
        <path
          d="M913 105.881C913 97.6626 906.337 91 898.119 91H109.407C101.188 91 94.5255 97.6626 94.5255 105.881V195.169C94.5255 203.388 101.188 210.051 109.407 210.051H913V105.881Z"
          fill="url(#paint0_linear_103_2281)"
        />
      </g>
      <path d="M847.522 248.742L913 210.051H847.522V248.742Z" fill="black" />
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="30"
        letterSpacing="0em"
      >
        <tspan x="225" y="337.755">
          You&#39;re not ready to prioritize your
          {' '}
        </tspan>
        <tspan x="225" y="373.755">
          dating life.
        </tspan>
      </text>
      <path
        d="M194.563 329.437C185.979 320.854 172.019 320.854 163.435 329.437C154.855 338.019 154.855 351.983 163.435 360.566C167.727 364.856 173.364 367 179 367C184.637 367 190.271 364.856 194.563 360.566C203.146 351.983 203.146 338.019 194.563 329.437ZM188.078 351.486C188.795 352.203 188.795 353.363 188.078 354.08C187.72 354.437 187.25 354.617 186.781 354.617C186.311 354.617 185.842 354.437 185.484 354.08L179 347.594L172.518 354.078C172.159 354.435 171.689 354.615 171.221 354.615C170.752 354.615 170.282 354.435 169.925 354.078C169.207 353.361 169.207 352.2 169.925 351.484L176.407 345L169.923 338.516C169.206 337.799 169.206 336.638 169.923 335.923C170.638 335.206 171.799 335.206 172.516 335.923L179 342.407L185.484 335.923C186.201 335.206 187.36 335.206 188.077 335.923C188.795 336.638 188.795 337.799 188.077 338.516L181.594 345L188.078 351.486Z"
        fill="#7E7E7E"
      />
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="30"
        letterSpacing="0em"
      >
        <tspan x="225" y="458.755">
          &#x200b;You&#39;re not willing to invest in
          {' '}
        </tspan>
        <tspan x="225" y="494.755">
          yourself.
        </tspan>
      </text>
      <path
        d="M191.148 452.852C183.345 445.049 170.654 445.049 162.85 452.852C155.05 460.654 155.05 473.348 162.85 481.15C166.752 485.05 171.876 486.999 177 486.999C182.124 486.999 187.247 485.05 191.148 481.15C198.951 473.348 198.951 460.654 191.148 452.852ZM185.252 472.896C185.904 473.548 185.904 474.602 185.252 475.254C184.927 475.579 184.5 475.742 184.073 475.742C183.647 475.742 183.22 475.579 182.895 475.254L177 469.358L171.107 475.252C170.781 475.577 170.354 475.741 169.928 475.741C169.502 475.741 169.075 475.577 168.75 475.252C168.098 474.6 168.098 473.544 168.75 472.894L174.642 467L168.748 461.105C168.096 460.453 168.096 459.398 168.748 458.748C169.398 458.096 170.454 458.096 171.106 458.748L177 464.642L182.894 458.748C183.546 458.096 184.6 458.096 185.252 458.748C185.904 459.398 185.904 460.453 185.252 461.105L179.358 467L185.252 472.896Z"
        fill="#7E7E7E"
      />
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="30"
        letterSpacing="0em"
      >
        <tspan x="225" y="579.755">
          &#x200b;You&#39;re not coachable and aren&#39;t
          {' '}
        </tspan>
        <tspan x="225" y="615.755">
          willing to accept feedback.
        </tspan>
      </text>
      <path
        d="M191.148 572.852C183.345 565.049 170.654 565.049 162.85 572.852C155.05 580.654 155.05 593.348 162.85 601.15C166.752 605.05 171.876 606.999 177 606.999C182.124 606.999 187.247 605.05 191.148 601.15C198.951 593.348 198.951 580.654 191.148 572.852ZM185.252 592.896C185.904 593.548 185.904 594.602 185.252 595.254C184.927 595.579 184.5 595.742 184.073 595.742C183.647 595.742 183.22 595.579 182.895 595.254L177 589.358L171.107 595.252C170.781 595.577 170.354 595.741 169.928 595.741C169.502 595.741 169.075 595.577 168.75 595.252C168.098 594.6 168.098 593.544 168.75 592.894L174.642 587L168.748 581.105C168.096 580.453 168.096 579.398 168.748 578.748C169.398 578.096 170.454 578.096 171.106 578.748L177 584.642L182.894 578.748C183.546 578.096 184.6 578.096 185.252 578.748C185.904 579.398 185.904 580.453 185.252 581.105L179.358 587L185.252 592.896Z"
        fill="#7E7E7E"
      />
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="30"
        letterSpacing="0em"
      >
        <tspan x="225" y="700.755">
          &#x200b;You make excuses and you&#39;re not
          {' '}
        </tspan>
        <tspan x="225" y="736.755">
          willing to do what it takes to see
          {' '}
        </tspan>
        <tspan x="225" y="772.755">
          real results.
        </tspan>
      </text>
      <path
        d="M191.148 695.852C183.345 688.049 170.654 688.049 162.85 695.852C155.05 703.654 155.05 716.348 162.85 724.15C166.752 728.05 171.876 729.999 177 729.999C182.124 729.999 187.247 728.05 191.148 724.15C198.951 716.348 198.951 703.654 191.148 695.852ZM185.252 715.896C185.904 716.548 185.904 717.602 185.252 718.254C184.927 718.579 184.5 718.742 184.073 718.742C183.647 718.742 183.22 718.579 182.895 718.254L177 712.358L171.107 718.252C170.781 718.577 170.354 718.741 169.928 718.741C169.502 718.741 169.075 718.577 168.75 718.252C168.098 717.6 168.098 716.544 168.75 715.894L174.642 710L168.748 704.105C168.096 703.453 168.096 702.398 168.748 701.748C169.398 701.096 170.454 701.096 171.106 701.748L177 707.642L182.894 701.748C183.546 701.096 184.6 701.096 185.252 701.748C185.904 702.398 185.904 703.453 185.252 704.105L179.358 710L185.252 715.896Z"
        fill="#7E7E7E"
      />
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="30"
        letterSpacing="0em"
      >
        <tspan x="225" y="857.755">
          &#x200b;You&#39;re not ready to commit to
          {' '}
        </tspan>
        <tspan x="225" y="893.755">
          changing your habits or your
          {' '}
        </tspan>
        <tspan x="225" y="929.755">
          lifestyle.
        </tspan>
      </text>
      <path
        d="M191.148 850.852C183.345 843.049 170.654 843.049 162.85 850.852C155.05 858.654 155.05 871.348 162.85 879.15C166.752 883.05 171.876 884.999 177 884.999C182.124 884.999 187.247 883.05 191.148 879.15C198.951 871.348 198.951 858.654 191.148 850.852ZM185.252 870.896C185.904 871.548 185.904 872.602 185.252 873.254C184.927 873.579 184.5 873.742 184.073 873.742C183.647 873.742 183.22 873.579 182.895 873.254L177 867.358L171.107 873.252C170.781 873.577 170.354 873.741 169.928 873.741C169.502 873.741 169.075 873.577 168.75 873.252C168.098 872.6 168.098 871.544 168.75 870.894L174.642 865L168.748 859.105C168.096 858.453 168.096 857.398 168.748 856.748C169.398 856.096 170.454 856.096 171.106 856.748L177 862.642L182.894 856.748C183.546 856.096 184.6 856.096 185.252 856.748C185.904 857.398 185.904 858.453 185.252 859.105L179.358 865L185.252 870.896Z"
        fill="#7E7E7E"
      />
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="30"
        letterSpacing="0em"
      >
        <tspan x="225" y="1014.75">
          &#x200b;You&#39;re &#34;content&#34; with where
          {' '}
        </tspan>
        <tspan x="225" y="1050.75">
          you&#39;re at right now and aren&#39;t
          {' '}
        </tspan>
        <tspan x="225" y="1086.75">
          motivated to make changes.
        </tspan>
      </text>
      <path
        d="M191.148 998.852C183.345 991.049 170.654 991.049 162.85 998.852C155.05 1006.65 155.05 1019.35 162.85 1027.15C166.752 1031.05 171.876 1033 177 1033C182.124 1033 187.247 1031.05 191.148 1027.15C198.951 1019.35 198.951 1006.65 191.148 998.852ZM185.252 1018.9C185.904 1019.55 185.904 1020.6 185.252 1021.25C184.927 1021.58 184.5 1021.74 184.073 1021.74C183.647 1021.74 183.22 1021.58 182.895 1021.25L177 1015.36L171.107 1021.25C170.781 1021.58 170.354 1021.74 169.928 1021.74C169.502 1021.74 169.075 1021.58 168.75 1021.25C168.098 1020.6 168.098 1019.54 168.75 1018.89L174.642 1013L168.748 1007.11C168.096 1006.45 168.096 1005.4 168.748 1004.75C169.398 1004.1 170.454 1004.1 171.106 1004.75L177 1010.64L182.894 1004.75C183.546 1004.1 184.6 1004.1 185.252 1004.75C185.904 1005.4 185.904 1006.45 185.252 1007.11L179.358 1013L185.252 1018.9Z"
        fill="#7E7E7E"
      />
      <text
        className="whitespace-pre"
        fill="white"
        xmlSpace="preserve"
        fontFamily="Oswald"
        fontSize="40"
        fontWeight="600"
        letterSpacing="0em"
      >
        <tspan x="519.562" y="170.58">
          For You
        </tspan>
      </text>
      <text
        className="whitespace-pre"
        fill="white"
        xmlSpace="preserve"
        fontFamily="Oswald"
        fontSize="40"
        fontWeight="600"
        letterSpacing="0em"
      >
        <tspan x="188" y="170.58">
          This Program is
          {' '}
        </tspan>
        <tspan fill="#9F2533" x="447.062" y="170.58">
          NOT
          {' '}
        </tspan>
        <tspan x="519.562" y="170.58">
          For You
        </tspan>
        <tspan x="637.688" y="170.58">
          {' '}
          if:
        </tspan>
      </text>
      <defs>
        <filter
          id="filter0_d_103_2281"
          x="0"
          y="0"
          width="882.523"
          height="1043.63"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="17.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_103_2281" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_103_2281"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_103_2281"
          x="79"
          y="221"
          width="731"
          height="1171.57"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="17.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_103_2281" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_103_2281"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_103_2281"
          x="73.6934"
          y="76.1186"
          width="872.046"
          height="172.624"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5.95254" dy="11.9051" />
          <feGaussianBlur stdDeviation="13.3932" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_103_2281" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11.9051" />
          <feGaussianBlur stdDeviation="5.95254" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_103_2281"
            result="effect2_dropShadow_103_2281"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_103_2281"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_103_2281"
          x1="907.047"
          y1="150.525"
          x2="94.5254"
          y2="150.525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E2224" />
          <stop offset="1" stopColor="#1E2224" stopOpacity="0.49" />
        </linearGradient>
      </defs>
    </svg>
  );
}
