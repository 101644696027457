import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { Link, graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import EmbedVideo from '../components/EmbedVideo';
import ProCard from '../components/ProCard';
import ConCard from '../components/ConCard';
import ConCardMobile from '../components/ConCardMobile';
import ProCardMobile from '../components/ProCardMobile';

declare global {
  interface Window { gtag: any; }
}

const testimonials = [
  {
    location: 'Dallas, TX.',
    name: 'Will C.',
    video: 'https://player.vimeo.com/video/656807616?h=9400ea3d34',
  },
  {
    location: 'Seattle, WA.',
    name: 'Emett S.',
    video: 'https://player.vimeo.com/video/656807741?h=8f059e124f',
  },
  {
    location: 'United Kingdom',
    name: 'Tom L.',
    video: 'https://player.vimeo.com/video/598863528?h=039b834ad2',
  },
  {
    location: 'Chicago, IL.',
    name: 'AJ S.',
    video: 'https://player.vimeo.com/video/598863559?h=67adcac8ba',
  },
  {
    location: 'Syracuse, NY.',
    name: 'Brian L.',
    video: 'https://player.vimeo.com/video/598863584?h=e6413b554e',
  },
  {
    location: 'Atlanta, GA.',
    name: 'Jaisen G.',
    video: 'https://player.vimeo.com/video/598863826?h=9b21097cea',
  },
  {
    location: 'Germany',
    name: 'Justin S.',
    video: 'https://player.vimeo.com/video/598863339?h=ada531d55d',
  },
  {
    location: 'Orlando, FL.',
    name: 'Will P.',
    video: 'https://player.vimeo.com/video/598863441?h=81137444c8',
  },
  {
    location: 'Los Angeles, CA.',
    name: 'Ryan L.',
    video: 'https://player.vimeo.com/video/598863477?h=ee416bcaa6',
  },
  {
    location: 'Seattle, WA.',
    name: 'Matt C.',
    video: 'https://player.vimeo.com/video/598863400?h=16783caf9a',
  },
  {
    location: 'Chicago, IL.',
    name: 'Jeff H.',
    video: 'https://player.vimeo.com/video/656807578?h=af7c787d52',
  },
  {
    location: 'Detroit, MI.',
    name: 'Federico B.',
    video: 'https://player.vimeo.com/video/696164925?h=636a261c0c',
  },
  {
    location: 'Phoenix, AZ.',
    name: 'Luke R.',
    video: 'https://player.vimeo.com/video/696164999?h=3fa844af60',
  },
  {
    location: 'San Diego, CA.',
    name: 'Benny R.',
    video: 'https://player.vimeo.com/video/696165051?h=f38c7e91e8',
  },
  {
    location: 'New Haven, CT.',
    name: 'Dallas C.',
    video: 'https://player.vimeo.com/video/696165133?h=03d82727ef',
  },
  {
    location: 'Des Moines, IA.',
    name: 'Peter F.',
    video: 'https://player.vimeo.com/video/696164849?h=0683d8b1ea',
  },
];

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        formulaDesktop: file(relativePath: { eq: "formula-bg.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2560) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        formulaMobile: file(relativePath: { eq: "formula-bg-mobile.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cardBackgroundDesktop: file(relativePath: { eq: "card-background-v2.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2560) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cardBackgroundMobile: file(relativePath: { eq: "card-background-mobile-v2.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 550) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  const formulaBackgroundDesktop = data.formulaDesktop.childImageSharp.fluid;
  const formulaBackgroundMobile = data.formulaMobile.childImageSharp.fluid;
  const cardBackgroundDesktop = data.cardBackgroundDesktop.childImageSharp.fluid;
  const cardBackgroundMobile = data.cardBackgroundMobile.childImageSharp.fluid;

  return (
    <Layout>
      <SEO title="Home" />

      <div className="hero-container overflow-hidden relative">
        <StaticImage
          alt=""
          className="hero-image"
          objectPosition="top"
          placeholder="blurred"
          src="../images/hero-bg.png"
        />

        <div className="absolute bg-white bg-opacity-70 inset-0">
          <div className="max-w-4xl mt-8 mx-auto px-4 text-center">
            <div className="mx-auto w-32 md:w-full">
              <StaticImage
                alt=""
                imgClassName="filter drop-shadow-2xl"
                placeholder="blurred"
                src="../images/logo.png"
              />
            </div>

            <p className="font-semibold my-4">HOW TO BEAST PRESENTS...</p>

            <h1 className="font-Oswald text-xl mb-8 sm:text-2xl lg:text-3xl xl:text-4xl">
              How Normal Guys are Unlocking Their Hidden Confidence &amp;
              Attracting Top Tier Women in less than 60 days.
            </h1>

            <div className="bg-red mb-16 mx-auto p-4 rounded-md shadow-md sm:max-w-md lg:max-w-lg xl:p-8 xl:max-w-xl 2xl:max-w-2xl">
              <EmbedVideo
                src="https://player.vimeo.com/video/597345315?h=aff4b7130f"
                title="intro"
                type="Vimeo"
              />
            </div>

            <Link
              className="bg-red px-8 py-4 rounded-md shadow-md text-white transition-opacity hover:opacity-70"
              to="/cta"
            >
              Show Me How
            </Link>
          </div>
        </div>
      </div>

      <div className="bg-black relative w-full">
        <div className="absolute bg-black h-72 transform skew-y-12 w-full" />
        <div className="absolute bg-black h-72 transform -skew-y-12 w-full" />

        <section className="pb-6 relative lg:pb-24">
          <div className="container mx-auto px-4">
            <div className="gap-4 grid items-center lg:grid-cols-2">
              <div className="max-w-xl mx-auto order-2 text-grey sm:text-center lg:text-left">
                <h2 className="font-Oswald mb-6 text-3xl md:text-4xl">
                  Who is
                  {' '}
                  <span className="text-red">David de las Morenas?</span>
                </h2>

                <p className="mb-4">Hi there, My name is David de las Morenas.</p>

                <p className="mb-4">
                  You may know me as the YouTuber with over 1 million subscribers,
                  but not long ago I was in your shoes.
                </p>

                <p className="mb-4">
                  I know what it&apos;s like to feel invisible to women.
                </p>

                <p className="mb-4">
                  Over the years, I endured hundreds of painful rejections on the path
                  to finding my &quot;hidden confidence&quot; and unlocking the ability to attract
                  the high quality women I always wanted.
                </p>

                <p className="mb-4">
                  Since then, I helped millions of men make this same transformation.
                </p>

                <p className="mb-4">
                  During this time, I saw the same continuous and predictable
                  roadblocks preventing men from realizing their full potential.
                </p>

                <p>
                  Accumulating the most effective strategies to break through these plateaus,
                  I designed a &quot;battle-tested&quot; system any man can use to take full
                  control of his dating life.
                </p>
              </div>

              <div className="mt-16 lg:mt-0 lg:order-2 lg:px-4 xl:px-0">
                <div className="hidden max-w-lg mx-auto lg:block">
                  <StaticImage alt="how to beast david morenas" src="../images/who.png" />
                </div>

                <div className="block mb-3 text-center lg:hidden">
                  <StaticImage
                    alt="how to beast david morenas"
                    className="w-72 sm:w-96"
                    src="../images/who-square.png"
                  />
                </div>
              </div>
            </div>

            <div className="max-w-3xl mt-8 mx-auto">
              <StaticImage alt="" src="../images/as-seen-in.png" />
            </div>
          </div>
        </section>
      </div>

      <section className="container mx-auto px-4 py-12">
        <h2 className="font-Oswald text-center text-3xl filter drop-shadow-text md:text-4xl">
          What Makes
          <span className="sr-only">Beast</span>
          <div className="align-bottom inline-block mx-1 w-28 md:w-32">
            <img alt="" src="/inline-logo.png" />
          </div>
          Coaching Different
        </h2>

        <div className="gap-8 grid items-center mt-12 lg:grid-cols-2">
          <div className="max-w-md mx-auto sm:max-w-lg lg:max-w-2xl">
            <StaticImage alt="" src="../images/what.png" />
          </div>

          <div>
            <div className="items-center mb-8 sm:flex">
              <div className="mb-4 mx-auto w-20 sm:mb-0 sm:mr-6">
                <StaticImage alt="" src="../images/feedback-icon.png" />
              </div>

              <div className="flex-1 text-center sm:text-left">
                <h3 className="font-Oswald mb-3 text-red text-2xl">
                  Personalized Feedback
                </h3>

                <p>
                  Most guys watch videos and read books, but nothing changes.
                  We give you feedback tailored to your situation, so you can crush
                  any roadblocks in your way.
                </p>
              </div>
            </div>

            <div className="items-center mb-8 sm:flex">
              <div className="mb-4 mx-auto w-20 sm:mb-0 sm:mr-6">
                <StaticImage alt="" src="../images/confidence-icon.png" />
              </div>

              <div className="flex-1 text-center sm:text-left">
                <h3 className="font-Oswald mb-3 text-red text-2xl">
                  Lasting Confidence
                </h3>

                <p>
                  This isn’t a 3-day bootcamp where you approach 100 girls in
                  a weekend, get a dopamine hit, then you’re back at square one.
                  We stick with you so you make lasting progress.
                </p>
              </div>
            </div>

            <div className="items-center mb-8 sm:flex">
              <div className="mb-4 mx-auto w-20 sm:mb-0 sm:mr-6">
                <StaticImage alt="" src="../images/gimmicks-icon.png" />
              </div>

              <div className="flex-1 text-center sm:text-left">
                <h3 className="font-Oswald mb-3 text-red text-2xl">
                  No Weird Gimmicks
                </h3>

                <p>
                  No cheesy pick-up lines or “scripts” to follow. Instead, we
                  provide a simple, but an easy-to-follow framework to supercharge
                  your confidence and meet women.
                </p>
              </div>
            </div>

            <div className="items-center mb-8 sm:flex">
              <div className="mb-4 mx-auto w-20 sm:mb-0 sm:mr-6">
                <StaticImage alt="" src="../images/brotherhood-icon.png" />
              </div>

              <div className="flex-1 text-center sm:text-left">
                <h3 className="font-Oswald mb-3 text-red text-2xl">
                  Brotherhood
                </h3>

                <p>
                  Become part of a group of men focused on improving their skills with
                  women &amp; leveling up.
                </p>
              </div>
            </div>

            <div className="items-center mb-8 sm:flex">
              <div className="mb-4 mx-auto w-20 sm:mb-0 sm:mr-6">
                <StaticImage alt="" src="../images/calls-icon.png" />
              </div>

              <div className="flex-1 text-center sm:text-left">
                <h3 className="font-Oswald mb-3 text-red text-2xl">
                  Live Calls
                </h3>

                <p>
                  Weekly video calls with Beast, Dave, Julia,
                  and the other coaches to make sure you have the
                  support you need to succeed.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center my-8 text-center lg:my-16">
          <div className="bg-red flex-1 h-0.5" />

          <Link
            className="bg-red mx-4 px-8 py-4 rounded-md shadow-md text-white transition-opacity hover:opacity-70 md:mx-16"
            to="/cta"
          >
            Get Started Now
          </Link>

          <div className="bg-red flex-1 h-0.5" />
        </div>
      </section>

      <BackgroundImage
        className="bg-top"
        fluid={[
          formulaBackgroundMobile,
          {
            ...formulaBackgroundDesktop,
            media: '(min-width: 640px)',
          },
        ]}
        Tag="section"
      >
        <div className="py-8 lg:py-20">
          <div className="absolute bg-black bg-opacity-70 inset-0" />

          <div className="relative flex inset-0 px-4 z-10 md:px-8">
            <div className="container m-auto">
              <div className="bg-red bg-opacity-50 border-red border-2 mb-8 py-4 rounded-md text-white md:py-8 lg:mb-20">
                <h2 className="font-Oswald text-center text-2xl sm:text-3xl md:text-4xl">
                  The
                  <span className="sr-only">Beast</span>
                  <div className="align-bottom inline-block mx-1 w-24 sm:w-28 md:w-32">
                    <img alt="" src="/inline-logo-white.png" />
                  </div>
                  Dating Formula
                </h2>
              </div>

              <div className="gap-8 grid justify-center justify-items-center sm:grid-cols-2 xl:gap-20 xl:grid-cols-6">
                <div className="bg-grey bg-opacity-70 border-white border-2 flex p-4 rounded-md sm:p-6 xl:bg-white xl:bg-opacity-70 xl:col-span-2 xl:p-8">
                  <div className="w-16 xl:w-24">
                    <StaticImage alt="" src="../images/talk-icon.png" />
                  </div>

                  <div className="flex-1">
                    <h3 className="font-Oswald formula-card-title mb-4 pb-2 relative text-xl md:text-2xl">
                      Talk to Any Girl
                    </h3>

                    <p className="text-sm md:text-base">
                      Overcome nervousness &amp; approach girls in any situation.
                      Plus, optimize your online profiles &amp; get more matches.
                    </p>
                  </div>
                </div>

                <div className="bg-grey bg-opacity-70 border-white border-2 flex p-4 rounded-md sm:p-6 xl:bg-white xl:bg-opacity-70 xl:col-span-2 xl:p-8">
                  <div className="w-16 xl:w-24">
                    <StaticImage alt="" src="../images/number-icon.png" />
                  </div>

                  <div className="flex-1">
                    <h3 className="font-Oswald formula-card-title mb-4 pb-2 relative text-xl md:text-2xl">
                      Get Her Number
                    </h3>

                    <p className="text-sm md:text-base">
                      Make an instant connection, keep the conversation going,
                      and get her phone number.
                    </p>
                  </div>
                </div>

                <div className="bg-grey bg-opacity-70 border-white border-2 flex p-4 rounded-md sm:p-6 xl:bg-white xl:bg-opacity-70 xl:col-span-2 xl:p-8">
                  <div className="w-16 xl:w-24">
                    <StaticImage alt="" src="../images/date-icon.png" />
                  </div>

                  <div className="flex-1">
                    <h3 className="font-Oswald formula-card-title mb-4 pb-2 relative text-xl md:text-2xl">
                      Get the Date
                    </h3>

                    <p className="text-sm md:text-base">
                      Follow our proven texting framework to quickly turn those
                      numbers and matches into dates.
                    </p>
                  </div>
                </div>

                <div className="bg-grey bg-opacity-70 border-white border-2 flex p-4 rounded-md sm:p-6 xl:bg-white xl:bg-opacity-70 xl:col-start-2 xl:col-end-4 xl:p-8">
                  <div className="w-16 xl:w-24">
                    <StaticImage alt="" src="../images/home-icon.png" />
                  </div>

                  <div className="flex-1">
                    <h3 className="font-Oswald formula-card-title mb-4 pb-2 relative text-xl md:text-2xl">
                      Bring Her Home
                    </h3>

                    <p className="text-sm md:text-base">
                      Take your dates to the next level and lead things back to the bedroom.
                      Be the dominant man she craves.
                    </p>
                  </div>
                </div>

                <div className="bg-grey bg-opacity-70 border-red border-2 flex p-4 rounded-md sm:col-span-2 sm:p-6 xl:bg-white xl:bg-opacity-70 xl:p-8">
                  <div className="w-16 xl:w-24">
                    <StaticImage alt="" src="../images/hooked-icon.png" />
                  </div>

                  <div className="flex-1">
                    <h3 className="font-Oswald formula-card-title mb-4 pb-2 relative text-red text-xl md:text-2xl">
                      Keep Her Hooked
                    </h3>

                    <p className="text-sm md:text-base">
                      Get past the 2nd date &amp; keep her interested. Have a relationship or keep
                      it casual, the choice is yours.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>

      <section className="container mx-auto px-4 py-12">
        <h2 className="font-Oswald mb-16 text-center text-3xl md:text-4xl">
          Student Success Stories
        </h2>

        <div className="gap-4 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {testimonials.map((elem) => (
            <div key={elem.name}>
              <EmbedVideo
                src={elem.video}
                title={`${elem.name} Success Story`}
                type="YouTube"
              />

              <h3 className="font-Oswald mb-1 mt-4 text-xl">
                {elem.name}
              </h3>

              <p className="text-sm">{elem.location}</p>
            </div>
          ))}
        </div>

        <div className="flex items-center my-8 text-center lg:my-16">
          <div className="bg-red flex-1 h-0.5" />

          <Link
            className="bg-red mx-4 px-8 py-4 rounded-md shadow-md text-white transition-opacity hover:opacity-70 md:mx-16"
            to="/cta"
          >
            Get Started Now
          </Link>

          <div className="bg-red flex-1 h-0.5" />
        </div>
      </section>

      <section className="mb-16 md:mb-32">
        <div className="bg-black relative w-full">
          <div className="absolute border-red border-l-2 border-r-2 border-t-2 inset-4 z-10 md:inset-8" />

          <div className="container mx-auto pb-24 pt-12 relative z-10">
            <h2 className="font-Oswald px-4 text-center text-grey text-2xl sm:text-3xl md:text-4xl">
              How the
              <span className="sr-only">Beast</span>
              <div className="align-bottom inline-block mx-1 w-24 sm:w-28 md:w-32">
                <img alt="" src="/inline-logo.png" />
              </div>
              Process Works
            </h2>

            <div className="gap-4 grid items-center mt-12 lg:grid-cols-2 lg:px-16">
              <div className="max-w-xs mx-auto md:max-w-sm lg:max-w-lg lg:pr-12 xl:pr-0">
                <StaticImage alt="" src="../images/how.png" />
              </div>

              <div className="mt-16 px-12 md:px-16 lg:mt-0 lg:px-0">
                <div className="border-red border-2 bg-grey mb-12 px-2 rounded-md transform -skew-x-12 md:px-4 lg:px-4">
                  <div className="flex">
                    <div className="bg-red flex ml-2 mr-6 -my-3 px-4 step-shadow md:mr-8 md:px-8 xl:ml-8">
                      <h4 className="m-auto text-center text-grey transform skew-x-12 filter drop-shadow-text">
                        <span className="font-bold font-Oswald text-3xl md:text-5xl">01</span>
                        <br />
                        <span className="font-medium text-base md:text-xl">STEP</span>
                      </h4>
                    </div>

                    <div className="py-4 transform skew-x-12 2xl:py-8">
                      <h4 className="font-Oswald mb-2 text-lg">YOU APPLY</h4>

                      <p className="text-sm md:text-base">
                        You fill out our quick no-stress application and give us some
                        basic info about you.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="border-red border-2 bg-grey mb-12 px-2 rounded-md transform -skew-x-12 md:px-4 lg:px-4">
                  <div className="flex">
                    <div className="bg-red flex ml-2 mr-6 -my-3 px-4 step-shadow md:mr-8 md:px-8 xl:ml-8">
                      <h4 className="m-auto text-center text-grey transform skew-x-12 filter drop-shadow-text">
                        <span className="font-bold font-Oswald text-3xl md:text-5xl">02</span>
                        <br />
                        <span className="font-medium text-base md:text-xl">STEP</span>
                      </h4>
                    </div>

                    <div className="py-4 transform skew-x-12 2xl:py-8">
                      <h4 className="font-Oswald mb-2 text-lg">WE REVIEW</h4>

                      <p className="text-sm md:text-base">
                        We go through your application to determine if you are a good fit.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="border-red border-2 bg-grey px-2 rounded-md transform -skew-x-12 md:px-4 lg:px-4">
                  <div className="flex">
                    <div className="bg-red flex ml-2 mr-6 -my-3 px-4 step-shadow md:mr-8 md:px-8 xl:ml-8">
                      <h4 className="m-auto text-center text-grey transform skew-x-12 filter drop-shadow-text">
                        <span className="font-bold font-Oswald text-3xl md:text-5xl">03</span>
                        <br />
                        <span className="font-medium text-base md:text-xl">STEP</span>
                      </h4>
                    </div>

                    <div className="py-4 transform skew-x-12 2xl:py-8">
                      <h4 className="font-Oswald mb-2 text-lg">STRATEGY CALL</h4>

                      <p className="text-sm md:text-base">
                        We&apos;ll give you valuable feedback on this call - and
                        we&apos;ll explore options to work together.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 h-72 transform skew-y-6 w-full bottom-slant-right" />
          <div className="absolute bottom-0 h-72 transform -skew-y-6 w-full bottom-slant-left" />
        </div>
      </section>

      <section className="max-w-6xl mx-auto px-4">
        <h2 className="font-Oswald mb-12 text-center text-3xl md:text-4xl md:mb-24">
          Meet the Other Coaches
        </h2>

        <div className="gap-12 grid sm:grid-cols-2">
          <div className="bg-white rounded-md shadow-xl">
            <StaticImage alt="" className="rounded-t-md" src="../images/dave.png" />

            <div className="p-4 md:p-8">
              <h3 className="coach-name font-Oswald mb-4 pb-2 relative text-2xl md:text-3xl">
                Dave Perrotta
              </h3>

              <p>
                Dave is our senior dating coach. He&apos;s honed his dating skills and
                knowledge through thousands of interactions over the years. He&apos;ll help
                you meet girls during the day, the night, and online, so you&apos;ve got a
                steady in-flow of dates with girls you&apos;re excited about.
              </p>
            </div>
          </div>

          <div className="bg-white rounded-md shadow-xl">
            <StaticImage alt="" className="rounded-t-md" src="../images/julia.png" />

            <div className="p-4 md:p-8">
              <h3 className="coach-name font-Oswald mb-4 pb-2 relative text-2xl md:text-3xl">
                Julia MacVane
              </h3>

              <p>
                Julia is our mindset expert. With her Masters in therapy,
                and countless hours of counseling experience, she will help you
                overcome any hidden &quot;confidence blocks&quot; that are preventing you from
                reaching your full potential.
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center my-24 text-center">
          <div className="bg-red flex-1 h-0.5" />

          <Link
            className="bg-red mx-4 px-8 py-4 rounded-md shadow-md text-white transition-opacity hover:opacity-70 md:mx-16"
            to="/cta"
          >
            Get Started Now
          </Link>

          <div className="bg-red flex-1 h-0.5" />
        </div>
      </section>

      <BackgroundImage
        className="card-background-image"
        fluid={[
          cardBackgroundMobile,
          {
            ...cardBackgroundDesktop,
            media: '(min-width: 550px)',
          },
        ]}
      >
        <div className="absolute bg-black bg-opacity-50 inset-0" />

        <section className="max-w-6xl pb-16 pt-16 mx-auto relative lg:pb-32 lg:px-16 xl:px-0">
          <div className="gap-4 grid md:grid-cols-2">
            <div className="hidden sm:block">
              <ProCard />
            </div>

            <div className="sm:hidden">
              <ProCardMobile />
            </div>

            <div className="hidden sm:block">
              <ConCard />
            </div>

            <div className="sm:hidden">
              <ConCardMobile />
            </div>
          </div>
        </section>

        <section className="footer">
          <div className="bg-black flex relative w-full">
            <div className="absolute border-red border-b-2 border-l-2 border-r-2 inset-4 z-10 md:inset-8" />

            <div className="max-w-xl m-auto px-8 py-16 relative text-center z-10 md:py-32 lg:max-w-3xl xl:py-28">
              <h2 className="font-Oswald mb-8 text-center text-grey text-2xl md:text-4xl">
                Are You Ready To Get The Women You Deserve?
              </h2>

              <p className="mb-8 text-grey md:text-xl">
                If you&apos;re ready to start winning with women, it&apos;s time
                to take the next step. Fill out your intro application and let&apos;s get started!
              </p>

              <Link
                className="bg-red inline-block px-8 py-4 rounded-md shadow-md text-white transition-opacity hover:opacity-70"
                to="/cta"
              >
                Get Started Now
              </Link>
            </div>

            <div className="absolute h-72 transform skew-y-6 w-full top-slant-right" />
            <div className="absolute h-72 transform -skew-y-6 w-full top-slant-left" />
          </div>
        </section>

        <div className="bg-black relative">
          <div className="flex justify-center pb-4 text-center text-sm text-white">
            <Link className="mx-4" to="/apply">Contact Us</Link>
            <Link className="mx-4" to="/terms">Terms &amp; Conditions</Link>
            <Link className="mx-4" to="/privacy-policy">Privacy Policy</Link>
          </div>

          <p className="pb-4 text-center text-sm text-white">
            &copy;
            {` ${new Date().getFullYear()} Beast Industries LLC`}
          </p>

          <p className="pb-4 text-center text-sm text-white">
            Designed &amp; Developed by
            {' '}
            <a href="https://exobyte.io" rel="noreferrer noopener" target="_blank">Exobyte</a>
          </p>
        </div>
      </BackgroundImage>
    </Layout>
  );
};

export default IndexPage;
