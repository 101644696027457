/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

const Layout: FunctionComponent = ({ children }) => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&amp;family=Oswald:wght@600;700&amp;display=swap" rel="stylesheet" />
    </Helmet>

    <div className="font-Montserrat">
      <main>{children}</main>
      <footer>

      </footer>
    </div>
  </>
);

export default Layout;
