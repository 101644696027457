import React from 'react';

export default function ConCardMobile() {
  return (
    <svg
      className="h-auto w-full"
      viewBox="0 0 368 604"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_167_621)">
        <path
          d="M338.998 395.092L43.1177 307.845C38.1444 306.379 34.7305 301.813 34.7305 296.628V24.6949C34.7305 18.236 39.9665 13 46.4254 13H342.306C348.765 13 354.001 18.236 354.001 24.6949V383.875C354.001 391.686 346.491 397.302 338.998 395.092Z"
          fill="#7F7F7F"
          fillOpacity="0.8"
        />
      </g>
      <g filter="url(#filter1_d_167_621)">
        <path
          d="M63 495.148V111.695C63 105.236 68.236 100 74.6949 100H311.305C317.764 100 323 105.236 323 111.695V576.605C323 584.636 315.092 590.277 307.498 587.663L70.888 506.206C66.1682 504.581 63 500.14 63 495.148Z"
          fill="white"
          fillOpacity="0.7"
        />
      </g>
      <g filter="url(#filter2_dd_167_621)">
        <path
          d="M9 42.8474C9 39.618 11.618 37 14.8474 37H337.153C340.382 37 343 39.618 343 42.8474V77.1526C343 80.382 340.382 83 337.153 83H9V42.8474Z"
          fill="url(#paint0_linear_167_621)"
        />
      </g>
      <path d="M34.7288 98.2034L9 83H34.7288V98.2034Z" fill="black" />
      <text
        className="whitespace-pre"
        fill="white"
        xmlSpace="preserve"
        fontFamily="Oswald"
        fontSize="18"
        fontWeight="600"
        letterSpacing="0em"
      >
        <tspan x="40" y="67.636">
          This Program is
          {' '}
        </tspan>
        <tspan fill="#9F2533" x="156.525" y="67.636">
          NOT
        </tspan>
        <tspan x="184.65" y="67.636">
          {' '}
          For You if:
        </tspan>
      </text>
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        letterSpacing="0em"
      >
        <tspan x="108.297" y="441.529">
          &#x200b;You&#39;re &#34;content&#34; with where
          {' '}
        </tspan>
        <tspan x="108.297" y="455.675">
          you&#39;re at right now and
          {' '}
        </tspan>
        <tspan x="108.297" y="469.821">
          aren&#39;t motivated to make
          {' '}
        </tspan>
        <tspan x="108.297" y="483.967">
          changes
        </tspan>
      </text>
      <path
        d="M94.5131 437.924C91.1966 434.609 85.8029 434.609 82.4863 437.924C79.1712 441.24 79.1712 446.635 82.4863 449.951C84.1446 451.609 86.3223 452.437 88.5001 452.437C90.6778 452.437 92.8548 451.609 94.5131 449.951C97.829 446.635 97.829 441.24 94.5131 437.924ZM92.0073 446.443C92.2843 446.72 92.2843 447.168 92.0073 447.445C91.8691 447.583 91.6877 447.653 91.5062 447.653C91.3248 447.653 91.1434 447.583 91.0052 447.445L88.5001 444.939L85.9956 447.445C85.8567 447.583 85.6753 447.652 85.4946 447.652C85.3132 447.652 85.1317 447.583 84.9936 447.445C84.7165 447.167 84.7165 446.719 84.9936 446.443L87.498 443.937L84.9929 441.432C84.7158 441.155 84.7158 440.707 84.9929 440.43C85.2692 440.153 85.7178 440.153 85.9949 440.43L88.5 442.935L91.0051 440.43C91.2822 440.153 91.7301 440.153 92.0072 440.43C92.2843 440.707 92.2843 441.155 92.0072 441.432L89.5021 443.937L92.0073 446.443Z"
        fill="#7E7E7E"
      />
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        letterSpacing="0em"
      >
        <tspan x="108.414" y="374.092">
          &#x200b;You&#39;re not ready to commit
          {' '}
        </tspan>
        <tspan x="108.414" y="388.238">
          to changing your habits or
          {' '}
        </tspan>
        <tspan x="108.414" y="402.383">
          your lifestyle
        </tspan>
      </text>
      <path
        d="M94.5131 368.487C91.1966 365.171 85.8029 365.171 82.4863 368.487C79.1712 371.803 79.1712 377.198 82.4863 380.514C84.1446 382.171 86.3223 383 88.5001 383C90.6778 383 92.8548 382.171 94.5131 380.514C97.829 377.198 97.829 371.803 94.5131 368.487ZM92.0073 377.006C92.2843 377.283 92.2843 377.731 92.0073 378.008C91.8691 378.146 91.6877 378.215 91.5062 378.215C91.3248 378.215 91.1434 378.146 91.0052 378.008L88.5001 375.502L85.9956 378.007C85.8567 378.145 85.6753 378.215 85.4946 378.215C85.3132 378.215 85.1317 378.145 84.9936 378.007C84.7165 377.73 84.7165 377.281 84.9936 377.005L87.498 374.5L84.9929 371.995C84.7158 371.718 84.7158 371.269 84.9929 370.993C85.2692 370.716 85.7178 370.716 85.9949 370.993L88.5 373.498L91.0051 370.993C91.2822 370.716 91.7301 370.716 92.0072 370.993C92.2843 371.269 92.2843 371.718 92.0072 371.995L89.5021 374.5L92.0073 377.006Z"
        fill="#7E7E7E"
      />
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        letterSpacing="0em"
      >
        <tspan x="107.883" y="307.092">
          &#x200b;You make excuses and
          {' '}
        </tspan>
        <tspan x="107.883" y="321.238">
          you&#39;re not willing to do what
          {' '}
        </tspan>
        <tspan x="107.883" y="335.383">
          it takes to see real results
        </tspan>
      </text>
      <path
        d="M94.5131 306.487C91.1966 303.171 85.8029 303.171 82.4863 306.487C79.1712 309.803 79.1712 315.198 82.4863 318.514C84.1446 320.171 86.3223 321 88.5001 321C90.6778 321 92.8548 320.171 94.5131 318.514C97.829 315.198 97.829 309.803 94.5131 306.487ZM92.0073 315.006C92.2843 315.283 92.2843 315.731 92.0073 316.008C91.8691 316.146 91.6877 316.215 91.5062 316.215C91.3248 316.215 91.1434 316.146 91.0052 316.008L88.5001 313.502L85.9956 316.007C85.8567 316.145 85.6753 316.215 85.4946 316.215C85.3132 316.215 85.1317 316.145 84.9936 316.007C84.7165 315.73 84.7165 315.281 84.9936 315.005L87.498 312.5L84.9929 309.995C84.7158 309.718 84.7158 309.269 84.9929 308.993C85.2692 308.716 85.7178 308.716 85.9949 308.993L88.5 311.498L91.0051 308.993C91.2822 308.716 91.7301 308.716 92.0072 308.993C92.2843 309.269 92.2843 309.718 92.0072 309.995L89.5021 312.5L92.0073 315.006Z"
        fill="#7E7E7E"
      />
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        letterSpacing="0em"
      >
        <tspan x="108.414" y="240.092">
          &#x200b;You&#39;re not coachable and
          {' '}
        </tspan>
        <tspan x="108.414" y="254.238">
          aren&#39;t willing to accept
          {' '}
        </tspan>
        <tspan x="108.414" y="268.383">
          feedback
        </tspan>
      </text>
      <path
        d="M94.5131 234.487C91.1966 231.171 85.8029 231.171 82.4863 234.487C79.1712 237.803 79.1712 243.198 82.4863 246.514C84.1446 248.171 86.3223 249 88.5001 249C90.6778 249 92.8548 248.171 94.5131 246.514C97.829 243.198 97.829 237.803 94.5131 234.487ZM92.0073 243.006C92.2843 243.283 92.2843 243.731 92.0073 244.008C91.8691 244.146 91.6877 244.215 91.5062 244.215C91.3248 244.215 91.1434 244.146 91.0052 244.008L88.5001 241.502L85.9956 244.007C85.8567 244.145 85.6753 244.215 85.4946 244.215C85.3132 244.215 85.1317 244.145 84.9936 244.007C84.7165 243.73 84.7165 243.281 84.9936 243.005L87.498 240.5L84.9929 237.995C84.7158 237.718 84.7158 237.269 84.9929 236.993C85.2692 236.716 85.7178 236.716 85.9949 236.993L88.5 239.498L91.0051 236.993C91.2822 236.716 91.7301 236.716 92.0072 236.993C92.2843 237.269 92.2843 237.718 92.0072 237.995L89.5021 240.5L92.0073 243.006Z"
        fill="#7E7E7E"
      />
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        letterSpacing="0em"
      >
        <tspan x="108.414" y="187.092">
          &#x200b;You&#39;re not willing to invest
          {' '}
        </tspan>
        <tspan x="108.414" y="201.238">
          in yourself
        </tspan>
      </text>
      <path
        d="M94.5131 181.487C91.1966 178.171 85.8029 178.171 82.4863 181.487C79.1712 184.803 79.1712 190.198 82.4863 193.514C84.1446 195.171 86.3223 196 88.5001 196C90.6778 196 92.8548 195.171 94.5131 193.514C97.829 190.198 97.829 184.803 94.5131 181.487ZM92.0073 190.006C92.2843 190.283 92.2843 190.731 92.0073 191.008C91.8691 191.146 91.6877 191.215 91.5062 191.215C91.3248 191.215 91.1434 191.146 91.0052 191.008L88.5001 188.502L85.9956 191.007C85.8567 191.145 85.6753 191.215 85.4946 191.215C85.3132 191.215 85.1317 191.145 84.9936 191.007C84.7165 190.73 84.7165 190.281 84.9936 190.005L87.498 187.5L84.9929 184.995C84.7158 184.718 84.7158 184.269 84.9929 183.993C85.2692 183.716 85.7178 183.716 85.9949 183.993L88.5 186.498L91.0051 183.993C91.2822 183.716 91.7301 183.716 92.0072 183.993C92.2843 184.269 92.2843 184.718 92.0072 184.995L89.5021 187.5L92.0073 190.006Z"
        fill="#7E7E7E"
      />
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        letterSpacing="0em"
      >
        <tspan x="108.414" y="134.329">
          You&#39;re not ready to prioritize
          {' '}
        </tspan>
        <tspan x="108.414" y="148.475">
          your dating life
        </tspan>
      </text>
      <path
        d="M94.5131 128.487C91.1966 125.171 85.8029 125.171 82.4863 128.487C79.1712 131.803 79.1712 137.198 82.4863 140.514C84.1446 142.171 86.3223 143 88.5001 143C90.6778 143 92.8548 142.171 94.5131 140.514C97.829 137.198 97.829 131.803 94.5131 128.487ZM92.0073 137.006C92.2843 137.283 92.2843 137.731 92.0073 138.008C91.8691 138.146 91.6877 138.215 91.5062 138.215C91.3248 138.215 91.1434 138.146 91.0052 138.008L88.5001 135.502L85.9956 138.007C85.8567 138.145 85.6753 138.215 85.4946 138.215C85.3132 138.215 85.1317 138.145 84.9936 138.007C84.7165 137.73 84.7165 137.281 84.9936 137.005L87.498 134.5L84.9929 131.995C84.7158 131.718 84.7158 131.269 84.9929 130.993C85.2692 130.716 85.7178 130.716 85.9949 130.993L88.5 133.498L91.0051 130.993C91.2822 130.716 91.7301 130.716 92.0072 130.993C92.2843 131.269 92.2843 131.718 92.0072 131.995L89.5021 134.5L92.0073 137.006Z"
        fill="#7E7E7E"
      />
      <defs>
        <filter
          id="filter0_d_167_621"
          x="20.9776"
          y="0.818931"
          width="346.775"
          height="410.083"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.57175" />
          <feGaussianBlur stdDeviation="6.87641" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_621" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_621"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_167_621"
          x="49.2472"
          y="87.8189"
          width="287.506"
          height="515.815"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.57175" />
          <feGaussianBlur stdDeviation="6.87641" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_621" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_621"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_167_621"
          x="0.813575"
          y="31.1526"
          width="355.051"
          height="67.0508"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.33898" dy="4.67796" />
          <feGaussianBlur stdDeviation="5.2627" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_621" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.67796" />
          <feGaussianBlur stdDeviation="2.33898" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_167_621"
            result="effect2_dropShadow_167_621"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_167_621"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_167_621"
          x1="11.4291"
          y1="60"
          x2="343"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E2224" />
          <stop offset="1" stopColor="#1E2224" stopOpacity="0.47" />
        </linearGradient>
      </defs>
    </svg>
  );
}
