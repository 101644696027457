import { Link } from 'gatsby';
import React from 'react';

export default function ProCardMobile() {
  return (
    <svg
      className="h-auto w-full"
      viewBox="0 0 368 609"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_167_384)">
        <path
          d="M338.998 395.092L43.1177 307.845C38.1444 306.379 34.7305 301.813 34.7305 296.628V24.6949C34.7305 18.236 39.9665 13 46.4254 13H342.306C348.765 13 354.001 18.236 354.001 24.6949V383.875C354.001 391.686 346.491 397.302 338.998 395.092Z"
          fill="#9F2533"
          fillOpacity="0.6"
        />
      </g>
      <g filter="url(#filter1_d_167_384)">
        <path
          d="M63 499.268V111.695C63 105.236 68.236 100 74.6949 100H311.305C317.764 100 323 105.236 323 111.695V581.551C323 589.597 315.064 595.24 307.464 592.597L70.8536 510.314C66.1515 508.679 63 504.246 63 499.268Z"
          fill="white"
          fillOpacity="0.7"
        />
      </g>
      <g filter="url(#filter2_dd_167_384)">
        <path
          d="M9 42.8474C9 39.618 11.618 37 14.8474 37H337.153C340.382 37 343 39.618 343 42.8474V77.1526C343 80.382 340.382 83 337.153 83H9V42.8474Z"
          fill="url(#paint0_linear_167_384)"
        />
      </g>
      <g filter="url(#filter3_dd_167_384)">
        <Link className="hover:opacity-70" to="/apply">
          <path
            d="M349.285 482.847C349.285 479.618 346.667 477 343.438 477H233.322C230.092 477 227.474 479.618 227.474 482.847V517.912C227.474 521.142 230.092 523.76 233.322 523.76H349.285V482.847Z"
            fill="url(#paint1_linear_167_384)"

          />
          <text
            className="whitespace-pre"
            fill="white"
            xmlSpace="preserve"
            fontFamily="Montserrat"
            fontSize="14"
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x="257" y="505.539">
              SIGN UP
            </tspan>
          </text>
        </Link>
      </g>
      <path d="M34.7288 98.2034L9 83H34.7288V98.2034Z" fill="#501018" />
      <path d="M323.592 538.983L349.32 523.779H323.592V538.983Z" fill="#501018" />
      <text
        className="whitespace-pre"
        fill="white"
        xmlSpace="preserve"
        fontFamily="Oswald"
        fontSize="18"
        fontWeight="600"
        letterSpacing="0em"
      >
        <tspan x="103" y="67.636">
          Coaching is Perfect For You if...
        </tspan>
      </text>
      <mask
        id="mask0_167_384"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="45"
        width="85"
        height="30"
      >
        <rect x="16" y="45" width="84.8079" height="29.4219" fill="url(#pattern0)" />
      </mask>
      <g mask="url(#mask0_167_384)">
        <rect x="14.0586" y="43.3379" width="87.775" height="34.0578" fill="white" />
      </g>
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        fontWeight="500"
        letterSpacing="0em"
      >
        <tspan x="206.988" y="148.475">
          {' '}
        </tspan>
      </text>
      <text
        className="whitespace-pre"
        fill="#9F2533"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        fontWeight="500"
        letterSpacing="0em"
      >
        <tspan x="108.414" y="148.475">
          conversations
        </tspan>
      </text>
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        letterSpacing="0em"
      >
        <tspan x="108.414" y="134.329">
          You struggle to hold
          {' '}
        </tspan>
        <tspan x="210.748" y="148.475">
          with
          {' '}
        </tspan>
        <tspan x="108.414" y="162.621">
          attractive women.
        </tspan>
      </text>
      <g filter="url(#filter4_d_167_384)">
        <path
          d="M93.0801 133.54C92.814 133.259 92.3703 133.247 92.0893 133.514L87.6146 137.757L85.4803 135.566C85.21 135.289 84.7668 135.283 84.4892 135.553C84.2119 135.823 84.2061 136.267 84.4761 136.544L87.0929 139.231C87.23 139.372 87.4123 139.443 87.595 139.443C87.7684 139.443 87.9417 139.379 88.0775 139.25L93.054 134.531C93.3348 134.264 93.3467 133.821 93.0801 133.54Z"
          fill="#9F2533"
        />
      </g>
      <path
        d="M88.7618 127.737C83.9951 127.737 80.1172 131.615 80.1172 136.382C80.1172 141.149 83.9951 145.027 88.7618 145.027C93.5285 145.027 97.4064 141.149 97.4064 136.382C97.4064 131.615 93.5285 127.737 88.7618 127.737ZM88.7618 143.625C84.7682 143.625 81.519 140.376 81.519 136.382C81.519 132.388 84.7682 129.139 88.7618 129.139C92.7556 129.139 96.0046 132.388 96.0046 136.382C96.0046 140.376 92.7556 143.625 88.7618 143.625Z"
        fill="#9F2533"
        fillOpacity="0.2"
      />
      <text
        className="whitespace-pre"
        fill="#9F2533"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        fontWeight="500"
        letterSpacing="0em"
      >
        <tspan x="236.643" y="213.238">
          struggle
        </tspan>
      </text>
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        letterSpacing="0em"
      >
        <tspan x="108.414" y="199.092">
          You&#x2019;re able to get phone
          {' '}
        </tspan>
        <tspan x="108.414" y="213.238">
          numbers, but you
          {' '}
        </tspan>
        <tspan x="296.484" y="213.238">
          {' '}
        </tspan>
        <tspan x="108.414" y="227.383">
          to get her out on a date.
        </tspan>
      </text>
      <g filter="url(#filter5_d_167_384)">
        <path
          d="M93.0801 199.09C92.814 198.809 92.3703 198.797 92.0893 199.064L87.6146 203.307L85.4803 201.116C85.21 200.838 84.7668 200.832 84.4892 201.103C84.2119 201.373 84.2061 201.817 84.4761 202.094L87.0929 204.78C87.23 204.921 87.4123 204.992 87.595 204.992C87.7684 204.992 87.9417 204.928 88.0775 204.8L93.054 200.081C93.3348 199.814 93.3467 199.371 93.0801 199.09Z"
          fill="#9F2533"
        />
      </g>
      <path
        d="M88.7618 193.288C83.9951 193.288 80.1172 197.166 80.1172 201.933C80.1172 206.699 83.9951 210.577 88.7618 210.577C93.5285 210.577 97.4064 206.699 97.4064 201.933C97.4064 197.166 93.5285 193.288 88.7618 193.288ZM88.7618 209.176C84.7682 209.176 81.519 205.927 81.519 201.933C81.519 197.939 84.7682 194.69 88.7618 194.69C92.7556 194.69 96.0046 197.939 96.0046 201.933C96.0046 205.927 92.7556 209.176 88.7618 209.176Z"
        fill="#9F2533"
        fillOpacity="0.2"
      />
      <text
        className="whitespace-pre"
        fill="#C5BCAC"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        letterSpacing="0em"
      >
        <tspan x="188.832" y="278.238">
          {' '}
        </tspan>
      </text>
      <text
        className="whitespace-pre"
        fill="#9F2533"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        fontWeight="500"
        letterSpacing="0em"
      >
        <tspan x="108.414" y="278.238">
          confidence
        </tspan>
      </text>
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        letterSpacing="0em"
      >
        <tspan x="108.414" y="264.092">
          You&#x2019;re ready to develop the
          {' '}
        </tspan>
        <tspan x="192.496" y="278.238">
          you need to
          {' '}
        </tspan>
        <tspan x="108.414" y="292.383">
          approach attractive women
          {' '}
        </tspan>
        <tspan x="108.414" y="306.529">
          in any environment.
        </tspan>
      </text>
      <g filter="url(#filter6_d_167_384)">
        <path
          d="M93.0801 267.234C92.814 266.954 92.3703 266.941 92.0893 267.208L87.6146 271.452L85.4803 269.26C85.21 268.983 84.7668 268.977 84.4892 269.247C84.2119 269.517 84.2061 269.961 84.4761 270.238L87.0929 272.925C87.23 273.066 87.4123 273.137 87.595 273.137C87.7684 273.137 87.9417 273.073 88.0775 272.945L93.054 268.225C93.3348 267.959 93.3467 267.515 93.0801 267.234Z"
          fill="#9F2533"
        />
      </g>
      <path
        d="M88.7618 261.431C83.9951 261.431 80.1172 265.309 80.1172 270.075C80.1172 274.842 83.9951 278.72 88.7618 278.72C93.5285 278.72 97.4064 274.842 97.4064 270.075C97.4064 265.309 93.5285 261.431 88.7618 261.431ZM88.7618 277.318C84.7682 277.318 81.519 274.069 81.519 270.075C81.519 266.082 84.7682 262.832 88.7618 262.832C92.7556 262.832 96.0046 266.082 96.0046 270.075C96.0046 274.069 92.7556 277.318 88.7618 277.318Z"
        fill="#9F2533"
        fillOpacity="0.2"
      />
      <text
        className="whitespace-pre"
        fill="#9F2533"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        fontWeight="500"
        letterSpacing="0em"
      >
        <tspan x="202.896" y="357.238">
          attracting
        </tspan>
      </text>
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        letterSpacing="0em"
      >
        <tspan x="108" y="343.092">
          You&#x2019;re having a tough time
          {' '}
        </tspan>
        <tspan x="108" y="357.238">
          meeting and
          {' '}
        </tspan>
        <tspan x="273.758" y="357.238">
          {' '}
        </tspan>
        <tspan x="108" y="371.383">
          women as a busy
          {' '}
        </tspan>
        <tspan x="108" y="385.529">
          professional.
        </tspan>
      </text>
      <g filter="url(#filter7_d_167_384)">
        <path
          d="M93.0801 340.732C92.814 340.452 92.3703 340.439 92.0893 340.706L87.6146 344.95L85.4803 342.758C85.21 342.481 84.7668 342.475 84.4892 342.745C84.2119 343.015 84.2061 343.459 84.4761 343.736L87.0929 346.423C87.23 346.564 87.4123 346.635 87.595 346.635C87.7684 346.635 87.9417 346.571 88.0775 346.443L93.054 341.723C93.3348 341.457 93.3467 341.013 93.0801 340.732Z"
          fill="#9F2533"
        />
      </g>
      <path
        d="M88.7618 334.931C83.9951 334.931 80.1172 338.809 80.1172 343.575C80.1172 348.342 83.9951 352.22 88.7618 352.22C93.5285 352.22 97.4064 348.342 97.4064 343.575C97.4064 338.809 93.5285 334.931 88.7618 334.931ZM88.7618 350.818C84.7682 350.818 81.519 347.569 81.519 343.575C81.519 339.582 84.7682 336.332 88.7618 336.332C92.7556 336.332 96.0046 339.582 96.0046 343.575C96.0046 347.569 92.7556 350.818 88.7618 350.818Z"
        fill="#9F2533"
        fillOpacity="0.2"
      />
      <text
        className="whitespace-pre"
        fill="#9F2533"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        fontWeight="500"
        letterSpacing="0em"
      >
        <tspan x="188.053" y="448.383">
          results.
        </tspan>
      </text>
      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="14"
        letterSpacing="0em"
      >
        <tspan x="108.414" y="420.092">
          You want to prioritize your
          {' '}
        </tspan>
        <tspan x="108.414" y="434.238">
          dating life and finally start
          {' '}
        </tspan>
        <tspan x="108.414" y="448.383">
          seeing real
          {' '}
        </tspan>
      </text>
      <g filter="url(#filter8_d_167_384)">
        <path
          d="M93.0801 418.91C92.814 418.629 92.3703 418.617 92.0893 418.884L87.6146 423.127L85.4803 420.936C85.21 420.659 84.7668 420.653 84.4892 420.923C84.2119 421.193 84.2061 421.637 84.4761 421.914L87.0929 424.601C87.23 424.742 87.4123 424.813 87.595 424.813C87.7684 424.813 87.9417 424.749 88.0775 424.62L93.054 419.901C93.3348 419.635 93.3467 419.191 93.0801 418.91Z"
          fill="#9F2533"
        />
      </g>
      <path
        d="M88.7618 413.108C83.9951 413.108 80.1172 416.986 80.1172 421.753C80.1172 426.52 83.9951 430.398 88.7618 430.398C93.5285 430.398 97.4064 426.52 97.4064 421.753C97.4064 416.986 93.5285 413.108 88.7618 413.108ZM88.7618 428.996C84.7682 428.996 81.519 425.747 81.519 421.753C81.519 417.759 84.7682 414.51 88.7618 414.51C92.7556 414.51 96.0046 417.759 96.0046 421.753C96.0046 425.747 92.7556 428.996 88.7618 428.996Z"
        fill="#9F2533"
        fillOpacity="0.2"
      />
      <defs>
        <filter
          id="filter0_d_167_384"
          x="20.9776"
          y="0.818931"
          width="346.775"
          height="410.083"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.57175" />
          <feGaussianBlur stdDeviation="6.87641" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_384" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_384"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_167_384"
          x="49.2472"
          y="87.8189"
          width="287.506"
          height="520.761"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.57175" />
          <feGaussianBlur stdDeviation="6.87641" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_384" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_384"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_167_384"
          x="0.813575"
          y="31.1526"
          width="355.051"
          height="67.0508"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.33898" dy="4.67796" />
          <feGaussianBlur stdDeviation="5.2627" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_384" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.67796" />
          <feGaussianBlur stdDeviation="2.33898" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_167_384"
            result="effect2_dropShadow_167_384"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_167_384"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_dd_167_384"
          x="219.286"
          y="471.153"
          width="142.863"
          height="67.8106"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.33898" dy="4.67796" />
          <feGaussianBlur stdDeviation="5.2627" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_384" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.67796" />
          <feGaussianBlur stdDeviation="2.33898" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_167_384"
            result="effect2_dropShadow_167_384"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_167_384"
            result="shape"
          />
        </filter>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use href="#image0_167_384" transform="scale(0.00438596 0.0126582)" />
        </pattern>
        <filter
          id="filter4_d_167_384"
          x="82.7056"
          y="133.321"
          width="12.1396"
          height="9.2646"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.57175" />
          <feGaussianBlur stdDeviation="0.785875" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_384" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_384"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_167_384"
          x="82.7056"
          y="198.871"
          width="12.1396"
          height="9.2646"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.57175" />
          <feGaussianBlur stdDeviation="0.785875" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_384" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_384"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_167_384"
          x="82.7056"
          y="267.016"
          width="12.1396"
          height="9.2646"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.57175" />
          <feGaussianBlur stdDeviation="0.785875" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_384" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_384"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_167_384"
          x="82.7056"
          y="340.514"
          width="12.1396"
          height="9.2646"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.57175" />
          <feGaussianBlur stdDeviation="0.785875" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_384" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_384"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_d_167_384"
          x="82.7056"
          y="418.691"
          width="12.1396"
          height="9.2646"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.57175" />
          <feGaussianBlur stdDeviation="0.785875" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_384" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_384"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_167_384"
          x1="11.4291"
          y1="60"
          x2="343"
          y2="60"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9F2533" />
          <stop offset="1" stopColor="#9F2533" stopOpacity="0.6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_167_384"
          x1="348.399"
          y1="500.38"
          x2="227.474"
          y2="500.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9F2533" />
          <stop offset="1" stopColor="#9F2533" stopOpacity="0.61" />
        </linearGradient>
        <image id="image0_167_384" width="228" height="79" href="/inline-logo-white.png" />
      </defs>
    </svg>
  );
}
