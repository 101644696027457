import { Link } from 'gatsby';
import React from 'react';

export default function ProCard() {
  return (
    <svg
      className="h-auto w-full"
      viewBox="0 0 934 1393"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_167_383)">
        <path
          d="M860.818 1003.4L107.821 781.362C95.1649 777.63 86.4766 766.01 86.4766 752.814V60.7627C86.4766 44.3252 99.8018 31 116.239 31H869.236C885.673 31 898.999 44.3252 898.999 60.7627V974.852C898.999 994.73 879.885 1009.02 860.818 1003.4Z"
          fill="#9F2533"
          fillOpacity="0.6"
        />
      </g>
      <g filter="url(#filter1_d_167_383)">
        <path
          d="M159 1140.08V281.763C159 265.325 172.325 252 188.763 252H790.237C806.675 252 820 265.325 820 281.763V1323.79C820 1343.79 800.67 1358.1 781.543 1352.25L180.069 1168.54C167.551 1164.72 159 1153.16 159 1140.08Z"
          fill="white"
          fillOpacity="0.7"
        />
      </g>
      <g filter="url(#filter2_dd_167_383)">
        <path
          d="M21 105.881C21 97.6626 27.6626 91 35.8814 91H824.593C832.812 91 839.475 97.6626 839.475 105.881V195.169C839.475 203.388 832.812 210.051 824.593 210.051H21V105.881Z"
          fill="url(#paint0_linear_167_383)"
        />
      </g>
      <g filter="url(#filter3_d_167_383)">
        <Link className="hover:opacity-70" to="/apply">
          <path
            d="M887 1124.88C887 1116.66 880.337 1110 872.119 1110H591.881C583.663 1110 577 1116.66 577 1124.88V1214.12C577 1222.34 583.663 1229 591.881 1229H887V1124.88Z"
            fill="url(#paint1_linear_167_383)"
          />

          <text
            className="font-bold font-Montserrat text-3xl whitespace-pre"
            fill="white"
            xmlSpace="preserve"
          >
            <tspan x="666" y="1180.25">
              SIGN UP
            </tspan>
          </text>
        </Link>
      </g>
      <path d="M86.478 248.742L21 210.051H86.478V248.742Z" fill="#501018" />
      <path d="M821.616 1267.74L887.094 1229.05H821.616V1267.74Z" fill="#501018" />
      <text
        className="whitespace-pre"
        fill="white"
        xmlSpace="preserve"
        fontFamily="Oswald"
        fontSize="40"
        fontWeight="600"
        letterSpacing="0em"
      >
        <tspan x="284" y="168.58">
          Coaching is Perfect For You if...
        </tspan>
      </text>

      <text
        fill="#212320"
        xmlSpace="preserve"
        className="whitespace-pre"
        fontFamily="Montserrat"
        fontSize="30"
        letterSpacing="0em"
      >
        <tspan x="274" y="337.755">
          You struggle to hold
          {' '}
        </tspan>
        <tspan className="font-medium" fill="#9F2533" x="274" y="373.755">
          conversations
        </tspan>
        <tspan className="" x="485.23" y="373.755">
          {' '}
          with attractive
          {' '}
        </tspan>
        <tspan x="274" y="409.755">
          women.
        </tspan>
      </text>

      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="30"
        letterSpacing="0em"
      >
        <tspan x="274" y="502.755">
          You&#x2019;re able to get phone
          {' '}
        </tspan>
        <tspan x="274" y="538.755">
          numbers, but you
          {' '}
        </tspan>
        <tspan className="font-medium" fill="#9F2533" x="548.775" y="538.755">
          struggle
        </tspan>
        <tspan x="677.008" y="538.755">
          {' '}
          to get
          {' '}
        </tspan>
        <tspan x="274" y="574.755">
          her out on a date.
        </tspan>
      </text>

      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="30"
        letterSpacing="0em"
      >
        <tspan x="274" y="661.755">
          You&#x2019;re ready to develop the
          {' '}
        </tspan>
        <tspan className="font-medium" fill="#9F2533" x="274" y="697.755">
          confidence
        </tspan>
        <tspan x="446.324" y="697.755">
          {' '}
          you need to approach
          {' '}
        </tspan>
        <tspan x="274" y="733.755">
          attractive women in any
          {' '}
        </tspan>
        <tspan x="274" y="769.755">
          environment.
        </tspan>
      </text>

      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="30"
        letterSpacing="0em"
      >
        <tspan x="274" y="842.755">
          You&#x2019;re having a tough time
          {' '}
        </tspan>
        <tspan x="274" y="878.755">
          meeting and
          {' '}
        </tspan>
        <tspan className="font-medium" fill="#9F2533" x="477.35" y="878.755">
          attracting
        </tspan>
        <tspan x="627.195" y="878.755">
          {' '}
          women
          {' '}
        </tspan>
        <tspan x="274" y="914.755">
          as a busy professional.
        </tspan>
      </text>

      <text
        className="whitespace-pre"
        fill="#212320"
        xmlSpace="preserve"
        fontFamily="Montserrat"
        fontSize="30"
        letterSpacing="0em"
      >
        <tspan x="274" y="1006.75">
          You want to prioritize your dating
          {' '}
        </tspan>
        <tspan x="274" y="1042.75">
          life and finally start seeing real
          {' '}
        </tspan>
        <tspan className="font-medium" fill="#9F2533" x="274" y="1078.75">
          results
        </tspan>
        <tspan x="375.865" y="1078.75">
          .
        </tspan>
      </text>

      <g filter="url(#filter4_d_167_383)">
        <path
          d="M234.988 337.767C234.311 337.053 233.182 337.022 232.467 337.701L221.079 348.5L215.647 342.924C214.96 342.218 213.832 342.202 213.125 342.89C212.419 343.578 212.405 344.707 213.092 345.413L219.751 352.25C220.1 352.608 220.564 352.789 221.029 352.789C221.47 352.789 221.912 352.626 222.257 352.3L234.922 340.289C235.637 339.611 235.667 338.482 234.988 337.767Z"
          fill="#9F2533"
        />
      </g>
      <path
        d="M224 323C211.869 323 202 332.869 202 345C202 357.131 211.869 367 224 367C236.131 367 246 357.131 246 345C246 332.869 236.131 323 224 323ZM224 363.432C213.837 363.432 205.568 355.164 205.568 345C205.568 334.837 213.837 326.568 224 326.568C234.164 326.568 242.432 334.837 242.432 345C242.432 355.164 234.164 363.432 224 363.432Z"
        fill="#9F2533"
        fillOpacity="0.2"
      />
      <g filter="url(#filter5_d_167_383)">
        <path
          d="M234.988 504.767C234.311 504.053 233.182 504.022 232.467 504.701L221.079 515.5L215.647 509.924C214.96 509.218 213.832 509.202 213.125 509.89C212.419 510.578 212.405 511.707 213.092 512.413L219.751 519.25C220.1 519.608 220.564 519.789 221.029 519.789C221.47 519.789 221.912 519.626 222.257 519.3L234.922 507.289C235.637 506.611 235.667 505.482 234.988 504.767Z"
          fill="#9F2533"
        />
      </g>
      <path
        d="M224 490C211.869 490 202 499.869 202 512C202 524.131 211.869 534 224 534C236.131 534 246 524.131 246 512C246 499.869 236.131 490 224 490ZM224 530.432C213.837 530.432 205.568 522.164 205.568 512C205.568 501.837 213.837 493.568 224 493.568C234.164 493.568 242.432 501.837 242.432 512C242.432 522.164 234.164 530.432 224 530.432Z"
        fill="#9F2533"
        fillOpacity="0.2"
      />
      <g filter="url(#filter6_d_167_383)">
        <path
          d="M234.988 671.767C234.311 671.053 233.182 671.022 232.467 671.701L221.079 682.5L215.647 676.924C214.96 676.218 213.832 676.202 213.125 676.89C212.419 677.578 212.405 678.707 213.092 679.413L219.751 686.25C220.1 686.608 220.564 686.789 221.029 686.789C221.47 686.789 221.912 686.626 222.257 686.3L234.922 674.289C235.637 673.611 235.667 672.482 234.988 671.767Z"
          fill="#9F2533"
        />
      </g>
      <path
        d="M224 657C211.869 657 202 666.869 202 679C202 691.131 211.869 701 224 701C236.131 701 246 691.131 246 679C246 666.869 236.131 657 224 657ZM224 697.432C213.837 697.432 205.568 689.164 205.568 679C205.568 668.837 213.837 660.568 224 660.568C234.164 660.568 242.432 668.837 242.432 679C242.432 689.164 234.164 697.432 224 697.432Z"
        fill="#9F2533"
        fillOpacity="0.2"
      />
      <g filter="url(#filter7_d_167_383)">
        <path
          d="M234.988 838.767C234.311 838.053 233.182 838.022 232.467 838.701L221.079 849.5L215.647 843.924C214.96 843.218 213.832 843.202 213.125 843.89C212.419 844.578 212.405 845.707 213.092 846.413L219.751 853.25C220.1 853.608 220.564 853.789 221.029 853.789C221.47 853.789 221.912 853.626 222.257 853.3L234.922 841.289C235.637 840.611 235.667 839.482 234.988 838.767Z"
          fill="#9F2533"
        />
      </g>
      <path
        d="M224 824C211.869 824 202 833.869 202 846C202 858.131 211.869 868 224 868C236.131 868 246 858.131 246 846C246 833.869 236.131 824 224 824ZM224 864.432C213.837 864.432 205.568 856.164 205.568 846C205.568 835.837 213.837 827.568 224 827.568C234.164 827.568 242.432 835.837 242.432 846C242.432 856.164 234.164 864.432 224 864.432Z"
        fill="#9F2533"
        fillOpacity="0.2"
      />
      <g filter="url(#filter8_d_167_383)">
        <path
          d="M234.988 1005.77C234.311 1005.05 233.182 1005.02 232.467 1005.7L221.079 1016.5L215.647 1010.92C214.96 1010.22 213.832 1010.2 213.125 1010.89C212.419 1011.58 212.405 1012.71 213.092 1013.41L219.751 1020.25C220.1 1020.61 220.564 1020.79 221.029 1020.79C221.47 1020.79 221.912 1020.63 222.257 1020.3L234.922 1008.29C235.637 1007.61 235.667 1006.48 234.988 1005.77Z"
          fill="#9F2533"
        />
      </g>
      <path
        d="M224 991C211.869 991 202 1000.87 202 1013C202 1025.13 211.869 1035 224 1035C236.131 1035 246 1025.13 246 1013C246 1000.87 236.131 991 224 991ZM224 1031.43C213.837 1031.43 205.568 1023.16 205.568 1013C205.568 1002.84 213.837 994.568 224 994.568C234.164 994.568 242.432 1002.84 242.432 1013C242.432 1023.16 234.164 1031.43 224 1031.43Z"
        fill="#9F2533"
        fillOpacity="0.2"
      />
      <rect x="56" y="111" width="228" height="79" fill="url(#pattern0)" />
      <defs>
        <filter
          id="filter0_d_167_383"
          x="51.4766"
          y="0"
          width="882.523"
          height="1043.63"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="17.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_383" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_383"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_167_383"
          x="124"
          y="221"
          width="731"
          height="1171.57"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="17.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_383" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_383"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_167_383"
          x="0.166102"
          y="76.1186"
          width="872.046"
          height="172.624"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5.95254" dy="11.9051" />
          <feGaussianBlur stdDeviation="13.3932" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_383" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11.9051" />
          <feGaussianBlur stdDeviation="5.95254" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_167_383"
            result="effect2_dropShadow_167_383"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_167_383"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_167_383"
          x="565.095"
          y="1110"
          width="333.81"
          height="142.81"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11.9051" />
          <feGaussianBlur stdDeviation="5.95254" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_383" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_383"
            result="shape"
          />
        </filter>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use href="#image0_167_383" transform="scale(0.00438596 0.0126582)" />
        </pattern>
        <filter
          id="filter4_d_167_383"
          x="208.586"
          y="337.211"
          width="30.8906"
          height="23.5781"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_383" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_383"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_167_383"
          x="208.586"
          y="504.211"
          width="30.8906"
          height="23.5781"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_383" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_383"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_167_383"
          x="208.586"
          y="671.211"
          width="30.8906"
          height="23.5781"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_383" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_383"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_167_383"
          x="208.586"
          y="838.211"
          width="30.8906"
          height="23.5781"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_383" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_383"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_d_167_383"
          x="208.586"
          y="1005.21"
          width="30.8906"
          height="23.5781"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_167_383" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_167_383"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_167_383"
          x1="26.9525"
          y1="150.525"
          x2="839.475"
          y2="150.525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9F2533" />
          <stop offset="1" stopColor="#9F2533" stopOpacity="0.6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_167_383"
          x1="884.745"
          y1="1169.5"
          x2="577"
          y2="1169.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9F2533" />
          <stop offset="1" stopColor="#9F2533" stopOpacity="0.61" />
        </linearGradient>
        <image id="image0_167_383" width="228" height="79" href="/inline-logo-white.png" />
      </defs>
    </svg>
  );
}
