import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

interface Props {
  src: string;
  title: string;
  type: 'Vimeo' | 'YouTube';
}

const EmbedVideo: FunctionComponent<Props> = ({ src, title, type }) => (
  <div className={clsx('overflow-hidden relative w-full', type === 'Vimeo' && 'iframe-vimeo-container', type === 'YouTube' && 'iframe-yt-container')}>
    <iframe
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="absolute h-full inset-0 w-full"
      frameBorder="0"
      src={src}
      title={title}
    />
  </div>
);

export default EmbedVideo;
